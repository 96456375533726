<template>
  <list-container-widget
    id="agenda"
    :app-id="app.id"
    :view-more-location="getLocation('agenda')"
    :size="addOnSize"
    stretch
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="agenda" />
    </template>
    <events-agenda
      :key="agendaKey"
      class="events-agenda"
      @events-updated="handleEventsUpdated"
      @update-page="updatePage()"
    />
    <!-- Create event -->
    <b-modal
      id="modal-create-agenda-event"
      :title="$t('events.actions.create-event')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :ok-disabled="!isNotDisabled"
      @ok="addEvent"
    >
      <b-form @change="$emit('update-event', eventInput)">
        <b-form-group>
          <label for="name">{{ $t("form-create-item.title") }}:</label>
          <b-form-input id="name" v-model="eventInput.name" type="text" />
        </b-form-group>
        <b-form-group>
          <label for="type">{{ $t("events.actions.type-options") }}:</label>
          <v-select
            id="type"
            v-model="selectedOption"
            :options="typeOptions"
            :clearable="false"
            :searchable="false"
            @input="modality"
          />
        </b-form-group>

        <!-- Organizer -->
        <b-form-group>
          <label for="type">{{ $t("events.actions.organizer.title") }}:</label>
          <b-form-checkbox v-model="showCreated" switch />
        </b-form-group>
        <b-form-group>
          <label
            class="mt-2"
            for="description"
          >{{ $t("form-create-item.description") }}:</label>
          <quill-editor v-model="eventInput.description" />
        </b-form-group>
        <b-form-group
          :label="`${$t('calendar.meeting-form.start-date.label')}*  (${$t(
            'events.event-details.local-time'
          )})`"
        >
          <VueCtkDateTimePicker
            v-model="eventInput.startDate"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            button-color="var(--primary)"
          />
        </b-form-group>
        <b-form-group
          :label="`${$t('calendar.meeting-form.end-date.label')}*  (${$t(
            'events.event-details.local-time'
          )})`"
        >
          <VueCtkDateTimePicker
            v-model="eventInput.endDate"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            button-color="var(--primary)"
            :min-date="eventInput.startDate"
          />
        </b-form-group>
        <div class="border rounded p-2">
          <p class="mb-50">
            {{ $t("form-create-item.image-select") }}
          </p>
          <b-media
            no-body
            vertical-align="center"
            class="d-flex align-items-center flex-column flex-md-row"
          >
            <b-media-aside>
              <b-img
                ref="refPreviewEl"
                :src="imageSrc"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
                style="background: #c0c0c0"
              />
            </b-media-aside>
            <b-media-body>
              <div class="d-inline-block d-flex align-items-center pl-2">
                <b-form-file
                  v-model="eventInput.thumbnailURL"
                  class="pointer"
                  :accept="FileTypes"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-form>
    </b-modal>
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import EventsAgenda from '@/views/apps/events/components/EventsAgenda';
import EventsHorizontalList from '@/views/apps/events/components/EventsHorizontalList.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import EventCard from '@/views/apps/events/components/EventCard.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { quillEditor } from 'vue-quill-editor';
//import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import vSelect from 'vue-select';
import { checkPermissions } from '@/@core/utils/roles-utils';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import FileTypes from '@/@core/constants/FileTypes';

export default {
  name: 'EventsAgendaWidget',
  components: {
    EventCard,
    ListContainerWidget,
    EventsAgenda,
    EventsHorizontalList,
    WidgetActions,
    quillEditor,
    vSelect,    
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  data() {
    return {
      eventInput: {},
      imageSrc: null,
      updateEvent: null,
      selectedOption: '',
      forceEvents: false,
      reload: 0,
      lastLoadedPage: 1,
      isLoadingNextPage: false,
      showCreated: false,
      valueSearch: '',
      events: [],
      agenda: false,
      agendaKey: 1,
      total: 0,
      eventsLength: 0,
    };
  },
  computed: {
    isNotDisabled() {
      return (
        this.eventInput.endDate && this.eventInput.startDate && this.eventInput.name
      );
    },
    FileTypes() {
      return FileTypes;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    typeOptions() {
      return [
        this.$t('events.event-details.online-event'),
        this.$t('events.event-details.on-site-event'),
        this.$t('events.event-details.hybrid'),
      ];
    },
    config() {
      return {
        wrap: true,
        enableTime: true,
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'Y-m-d h:i K',
        minuteIncrement: 1,
        disableMobile: 'true',
      };
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'event',
        this.loggedMemberRoles,
        this.currentCollective,
      );
    },
    canList() {
      return checkPermissions(
        'view',
        'event',
        this.loggedMemberRoles,
        this.currentCollective,
      );
    },
    canView() {
      return checkPermissions(
        'view',
        'event',
        this.loggedMemberRoles,
        this.currentCollective,
      );
    },
    eventPlaceholder() {
      return EventsPlaceholder;
    },
  },
  async created() {
    this.isLoading = true;
    this.selectedOption = this.typeOptions[0];
    await this.fetchAgenda();
    this.isLoading = false;
  },
  methods: {
    async fetchAgenda() {
      this.agenda = this.$store.getters.apps.apps[53]?.customization?.displayOptions || this.$store.getters.apps.apps[53]?.customization?.agendaDisplayOptions;
    },
    async handleEventsUpdated(events) {
      this.setAddOnSize(events.length);
    },
    async updatePage() {
      if (!this.isLoading && this.items?.length < this.total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchEvent();
        this.agendaKey += 1;
        this.isLoadingNextPage = false;
      }
    },
    /*     async updatePage() {
      if (this.eventsLength < this.total) {
        this.fetchEvent();
        this.agendaKey += 1;
      }
    }, */
    searchItems(value) {
      this.valueSearch = value;
    },
    async handleEditEvent(item) {
      this.updateEvent = item;
    },
    unixTimestamp(date) {
      const newDate = new Date(date);
      return Math.floor(newDate.getTime());
    },
    async addEvent() {
      try {
        const startDate = this.eventInput.startDate ? this.unixTimestamp(new Date(this.eventInput.startDate)) : null;
        const endDate = this.eventInput.endDate ? this.unixTimestamp(new Date(this.eventInput.endDate)) : null;
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'events',
            page: this.lastLoadedPage,
            perPage: 100,
            communitySlug: this.currentCollective?.slug,
            customName: 'agenda',
            requestConfig: {
              name: { en: this.eventInput.name },
              description: { en: this.eventInput.description },
              startDate,
              endDate,
              withShared: true,
              modality: this.eventInput.modality,
              showCreated: this.showCreated,
              isMeeting: 0,
              type: 27452,
              thumbnailURL: this.eventInput.thumbnailURL,
              orderByDate: -1,
            },
          },
        });

        this.eventInput = {};
        this.notifySuccess(this.$t('events.actions.success-creation'));
        this.forceEvents = true;
        this.agendaKey += 1;
      } catch (error) {
        this.notifyError(this.$t('events.actions.error-creation'));
        this.eventInput = {};
      }
    },
    async fetchEvent() {
      await this.$store.dispatch('getItems', {
        itemType: 'events',
        page: 1,
        forceAPICall: true,
        perPage: 100,
        customName: 'agenda',
        requestConfig: {
          visibleOncommunityKey: this.currentCollective.key,
          isForAgenda: true,
        },
      });

      const response = this.$store.getters.agenda;

      this.total = response.meta ? response.meta.total : 0;

      return response?.data;
    },
    modality() {
      switch (this.selectedOption) {
        case this.typeOptions[1]:
          this.eventInput.modality = 0;
          return;
        case this.typeOptions[2]:
          this.eventInput.modality = 2;
          return;
        case this.typeOptions[0]:
          this.eventInput.modality = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.events-agenda {
  flex: 1 1 1px;
}
.landing-layout {
  .events-agenda {
    min-height: 50vh;
  }
}
</style>
